
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexUHpQXAZ9rMsLQTmzrrLl6bWIyEwBiegNoZMRgnP3bJYMeta } from "/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/pages/index.vue?macro=true";
import { default as index9lc5vAMYIeAAI3ITSCO1ECZVKEOPv7P33jD87oR_45EbgMeta } from "/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/pages/[[lang]]/index.vue?macro=true";
import { default as startP8SXQhz5XwSKew3pWj8nLKyqWqtEz1Yi1NCieAZr1aMMeta } from "/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/pages/[[lang]]/voucher/start.vue?macro=true";
import { default as startd8N_45fyKvSEcpByTWH2uUBs2_4V7ED5WcjwNU07oTTbEMeta } from "/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/pages/[[lang]]/purchase/start.vue?macro=true";
import { default as checkoutYLbuHysXOYSfq9lDAf0LWN6rDCCmS3y_tWSGIvW_u_45sMeta } from "/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/pages/[[lang]]/service-booking/checkout.vue?macro=true";
import { default as koFKWB3GYyHoxv8O_Dh_45lKutkg7qb0tA8BypLu1tFu7d4Meta } from "/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/ko.vue?macro=true";
import { default as confirmationaHh2RqiI4SZnIpmYN5LGCMdizrzc61UAg20NbuhHQMoMeta } from "/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/confirmation.vue?macro=true";
import { default as confirmationHVdFb0MKTXlk2KuU1UlYW_m8Mjny7vgECOy3tdcueWAMeta } from "/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/confirmation.vue?macro=true";
import { default as buyermrmq_45qlj02JR7WFaQpo_cI504rKhGzYEyO5PPZHmQygMeta } from "/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/buyer.vue?macro=true";
import { default as buyernEDDm8jBg3G1ABcz11CPan3nTYWdYNLmlTw5Qd9zcf0Meta } from "/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/buyer.vue?macro=true";
import { default as payment3JyP4_AnPju0v_45ooQuCHu7cud2H6ZzFM4MUoi2_j7jIMeta } from "/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/payment.vue?macro=true";
import { default as challengeUq_45hA9pH1AqbxCBRjON8ttcLSncSPRItJs61UjtHOx4Meta } from "/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/redsys/challenge.vue?macro=true";
import { default as paymentP_QfDfTpezWvtYodK8kYx6QYus8kZ_45y3mj59mG7IW9sMeta } from "/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/payment.vue?macro=true";
import { default as SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta } from "/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaList.page.vue?macro=true";
import { default as SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta } from "/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaDetail.page.vue?macro=true";
import { default as ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta } from "/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue?macro=true";
export default [
  {
    name: indexUHpQXAZ9rMsLQTmzrrLl6bWIyEwBiegNoZMRgnP3bJYMeta?.name ?? "index",
    path: indexUHpQXAZ9rMsLQTmzrrLl6bWIyEwBiegNoZMRgnP3bJYMeta?.path ?? "/",
    props: indexUHpQXAZ9rMsLQTmzrrLl6bWIyEwBiegNoZMRgnP3bJYMeta?.props ?? false,
    meta: indexUHpQXAZ9rMsLQTmzrrLl6bWIyEwBiegNoZMRgnP3bJYMeta || {},
    alias: indexUHpQXAZ9rMsLQTmzrrLl6bWIyEwBiegNoZMRgnP3bJYMeta?.alias || [],
    redirect: indexUHpQXAZ9rMsLQTmzrrLl6bWIyEwBiegNoZMRgnP3bJYMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/pages/index.vue")
  },
  {
    name: index9lc5vAMYIeAAI3ITSCO1ECZVKEOPv7P33jD87oR_45EbgMeta?.name ?? "lang",
    path: index9lc5vAMYIeAAI3ITSCO1ECZVKEOPv7P33jD87oR_45EbgMeta?.path ?? "/:lang?",
    props: index9lc5vAMYIeAAI3ITSCO1ECZVKEOPv7P33jD87oR_45EbgMeta?.props ?? false,
    meta: index9lc5vAMYIeAAI3ITSCO1ECZVKEOPv7P33jD87oR_45EbgMeta || {},
    alias: index9lc5vAMYIeAAI3ITSCO1ECZVKEOPv7P33jD87oR_45EbgMeta?.alias || [],
    redirect: index9lc5vAMYIeAAI3ITSCO1ECZVKEOPv7P33jD87oR_45EbgMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/pages/[[lang]]/index.vue")
  },
  {
    name: startP8SXQhz5XwSKew3pWj8nLKyqWqtEz1Yi1NCieAZr1aMMeta?.name ?? "lang-voucher-start",
    path: startP8SXQhz5XwSKew3pWj8nLKyqWqtEz1Yi1NCieAZr1aMMeta?.path ?? "/:lang?/voucher/start",
    props: startP8SXQhz5XwSKew3pWj8nLKyqWqtEz1Yi1NCieAZr1aMMeta?.props ?? false,
    meta: startP8SXQhz5XwSKew3pWj8nLKyqWqtEz1Yi1NCieAZr1aMMeta || {},
    alias: startP8SXQhz5XwSKew3pWj8nLKyqWqtEz1Yi1NCieAZr1aMMeta?.alias || [],
    redirect: startP8SXQhz5XwSKew3pWj8nLKyqWqtEz1Yi1NCieAZr1aMMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/pages/[[lang]]/voucher/start.vue")
  },
  {
    name: startd8N_45fyKvSEcpByTWH2uUBs2_4V7ED5WcjwNU07oTTbEMeta?.name ?? "lang-purchase-start",
    path: startd8N_45fyKvSEcpByTWH2uUBs2_4V7ED5WcjwNU07oTTbEMeta?.path ?? "/:lang?/purchase/start",
    props: startd8N_45fyKvSEcpByTWH2uUBs2_4V7ED5WcjwNU07oTTbEMeta?.props ?? false,
    meta: startd8N_45fyKvSEcpByTWH2uUBs2_4V7ED5WcjwNU07oTTbEMeta || {},
    alias: startd8N_45fyKvSEcpByTWH2uUBs2_4V7ED5WcjwNU07oTTbEMeta?.alias || [],
    redirect: startd8N_45fyKvSEcpByTWH2uUBs2_4V7ED5WcjwNU07oTTbEMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/pages/[[lang]]/purchase/start.vue")
  },
  {
    name: checkoutYLbuHysXOYSfq9lDAf0LWN6rDCCmS3y_tWSGIvW_u_45sMeta?.name ?? "lang-service-booking-checkout",
    path: checkoutYLbuHysXOYSfq9lDAf0LWN6rDCCmS3y_tWSGIvW_u_45sMeta?.path ?? "/:lang?/service-booking/checkout",
    props: checkoutYLbuHysXOYSfq9lDAf0LWN6rDCCmS3y_tWSGIvW_u_45sMeta?.props ?? false,
    meta: checkoutYLbuHysXOYSfq9lDAf0LWN6rDCCmS3y_tWSGIvW_u_45sMeta || {},
    alias: checkoutYLbuHysXOYSfq9lDAf0LWN6rDCCmS3y_tWSGIvW_u_45sMeta?.alias || [],
    redirect: checkoutYLbuHysXOYSfq9lDAf0LWN6rDCCmS3y_tWSGIvW_u_45sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/pages/[[lang]]/service-booking/checkout.vue")
  },
  {
    name: koFKWB3GYyHoxv8O_Dh_45lKutkg7qb0tA8BypLu1tFu7d4Meta?.name ?? "lang-purchase-packagepurchaseuuid-ko",
    path: koFKWB3GYyHoxv8O_Dh_45lKutkg7qb0tA8BypLu1tFu7d4Meta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/ko",
    props: koFKWB3GYyHoxv8O_Dh_45lKutkg7qb0tA8BypLu1tFu7d4Meta?.props ?? false,
    meta: koFKWB3GYyHoxv8O_Dh_45lKutkg7qb0tA8BypLu1tFu7d4Meta || {},
    alias: koFKWB3GYyHoxv8O_Dh_45lKutkg7qb0tA8BypLu1tFu7d4Meta?.alias || [],
    redirect: koFKWB3GYyHoxv8O_Dh_45lKutkg7qb0tA8BypLu1tFu7d4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/ko.vue")
  },
  {
    name: confirmationaHh2RqiI4SZnIpmYN5LGCMdizrzc61UAg20NbuhHQMoMeta?.name ?? "lang-voucher-voucherpurchaseuuid-confirmation",
    path: confirmationaHh2RqiI4SZnIpmYN5LGCMdizrzc61UAg20NbuhHQMoMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/confirmation",
    props: confirmationaHh2RqiI4SZnIpmYN5LGCMdizrzc61UAg20NbuhHQMoMeta?.props ?? false,
    meta: confirmationaHh2RqiI4SZnIpmYN5LGCMdizrzc61UAg20NbuhHQMoMeta || {},
    alias: confirmationaHh2RqiI4SZnIpmYN5LGCMdizrzc61UAg20NbuhHQMoMeta?.alias || [],
    redirect: confirmationaHh2RqiI4SZnIpmYN5LGCMdizrzc61UAg20NbuhHQMoMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/confirmation.vue")
  },
  {
    name: confirmationHVdFb0MKTXlk2KuU1UlYW_m8Mjny7vgECOy3tdcueWAMeta?.name ?? "lang-purchase-packagepurchaseuuid-confirmation",
    path: confirmationHVdFb0MKTXlk2KuU1UlYW_m8Mjny7vgECOy3tdcueWAMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/confirmation",
    props: confirmationHVdFb0MKTXlk2KuU1UlYW_m8Mjny7vgECOy3tdcueWAMeta?.props ?? false,
    meta: confirmationHVdFb0MKTXlk2KuU1UlYW_m8Mjny7vgECOy3tdcueWAMeta || {},
    alias: confirmationHVdFb0MKTXlk2KuU1UlYW_m8Mjny7vgECOy3tdcueWAMeta?.alias || [],
    redirect: confirmationHVdFb0MKTXlk2KuU1UlYW_m8Mjny7vgECOy3tdcueWAMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/confirmation.vue")
  },
  {
    name: buyermrmq_45qlj02JR7WFaQpo_cI504rKhGzYEyO5PPZHmQygMeta?.name ?? "lang-voucher-voucherpurchaseuuid-checkout-buyer",
    path: buyermrmq_45qlj02JR7WFaQpo_cI504rKhGzYEyO5PPZHmQygMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/checkout/buyer",
    props: buyermrmq_45qlj02JR7WFaQpo_cI504rKhGzYEyO5PPZHmQygMeta?.props ?? false,
    meta: buyermrmq_45qlj02JR7WFaQpo_cI504rKhGzYEyO5PPZHmQygMeta || {},
    alias: buyermrmq_45qlj02JR7WFaQpo_cI504rKhGzYEyO5PPZHmQygMeta?.alias || [],
    redirect: buyermrmq_45qlj02JR7WFaQpo_cI504rKhGzYEyO5PPZHmQygMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/buyer.vue")
  },
  {
    name: buyernEDDm8jBg3G1ABcz11CPan3nTYWdYNLmlTw5Qd9zcf0Meta?.name ?? "lang-purchase-packagepurchaseuuid-checkout-buyer",
    path: buyernEDDm8jBg3G1ABcz11CPan3nTYWdYNLmlTw5Qd9zcf0Meta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/checkout/buyer",
    props: buyernEDDm8jBg3G1ABcz11CPan3nTYWdYNLmlTw5Qd9zcf0Meta?.props ?? false,
    meta: buyernEDDm8jBg3G1ABcz11CPan3nTYWdYNLmlTw5Qd9zcf0Meta || {},
    alias: buyernEDDm8jBg3G1ABcz11CPan3nTYWdYNLmlTw5Qd9zcf0Meta?.alias || [],
    redirect: buyernEDDm8jBg3G1ABcz11CPan3nTYWdYNLmlTw5Qd9zcf0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/buyer.vue")
  },
  {
    name: payment3JyP4_AnPju0v_45ooQuCHu7cud2H6ZzFM4MUoi2_j7jIMeta?.name ?? "lang-voucher-voucherpurchaseuuid-checkout-payment",
    path: payment3JyP4_AnPju0v_45ooQuCHu7cud2H6ZzFM4MUoi2_j7jIMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/checkout/payment",
    props: payment3JyP4_AnPju0v_45ooQuCHu7cud2H6ZzFM4MUoi2_j7jIMeta?.props ?? false,
    meta: payment3JyP4_AnPju0v_45ooQuCHu7cud2H6ZzFM4MUoi2_j7jIMeta || {},
    alias: payment3JyP4_AnPju0v_45ooQuCHu7cud2H6ZzFM4MUoi2_j7jIMeta?.alias || [],
    redirect: payment3JyP4_AnPju0v_45ooQuCHu7cud2H6ZzFM4MUoi2_j7jIMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/checkout/payment.vue")
  },
  {
    name: challengeUq_45hA9pH1AqbxCBRjON8ttcLSncSPRItJs61UjtHOx4Meta?.name ?? "lang-voucher-voucherpurchaseuuid-redsys-challenge",
    path: challengeUq_45hA9pH1AqbxCBRjON8ttcLSncSPRItJs61UjtHOx4Meta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/redsys/challenge",
    props: challengeUq_45hA9pH1AqbxCBRjON8ttcLSncSPRItJs61UjtHOx4Meta?.props ?? false,
    meta: challengeUq_45hA9pH1AqbxCBRjON8ttcLSncSPRItJs61UjtHOx4Meta || {},
    alias: challengeUq_45hA9pH1AqbxCBRjON8ttcLSncSPRItJs61UjtHOx4Meta?.alias || [],
    redirect: challengeUq_45hA9pH1AqbxCBRjON8ttcLSncSPRItJs61UjtHOx4Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/pages/[[lang]]/voucher/[voucherpurchaseuuid]/redsys/challenge.vue")
  },
  {
    name: paymentP_QfDfTpezWvtYodK8kYx6QYus8kZ_45y3mj59mG7IW9sMeta?.name ?? "lang-purchase-packagepurchaseuuid-checkout-payment",
    path: paymentP_QfDfTpezWvtYodK8kYx6QYus8kZ_45y3mj59mG7IW9sMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/checkout/payment",
    props: paymentP_QfDfTpezWvtYodK8kYx6QYus8kZ_45y3mj59mG7IW9sMeta?.props ?? false,
    meta: paymentP_QfDfTpezWvtYodK8kYx6QYus8kZ_45y3mj59mG7IW9sMeta || {},
    alias: paymentP_QfDfTpezWvtYodK8kYx6QYus8kZ_45y3mj59mG7IW9sMeta?.alias || [],
    redirect: paymentP_QfDfTpezWvtYodK8kYx6QYus8kZ_45y3mj59mG7IW9sMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/pages/[[lang]]/purchase/[packagepurchaseuuid]/checkout/payment.vue")
  },
  {
    name: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.name ?? "Spa by location landing: 0",
    path: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.path ?? "/spa-balneario/",
    props: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.props ?? false,
    meta: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta || {},
    alias: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.alias || [],
    redirect: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.name ?? "Spa by location landing: 1",
    path: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.path ?? "/spa-balneario/alicante/",
    props: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.props ?? false,
    meta: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta || {},
    alias: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.alias || [],
    redirect: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.name ?? "Spa by location landing: 2",
    path: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.path ?? "/spa-balneario/alicante/alicante/",
    props: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.props ?? false,
    meta: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta || {},
    alias: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.alias || [],
    redirect: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.name ?? "Spa by location landing: 3",
    path: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.path ?? "/spa-balneario/alicante/altea/",
    props: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.props ?? false,
    meta: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta || {},
    alias: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.alias || [],
    redirect: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.name ?? "Spa by location landing: 4",
    path: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.path ?? "/spa-balneario/alicante/benidorm/",
    props: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.props ?? false,
    meta: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta || {},
    alias: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.alias || [],
    redirect: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.name ?? "Spa by location landing: 5",
    path: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.path ?? "/spa-balneario/alicante/calpe/",
    props: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.props ?? false,
    meta: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta || {},
    alias: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.alias || [],
    redirect: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.name ?? "Spa by location landing: 6",
    path: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.path ?? "/spa-balneario/alicante/denia/",
    props: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.props ?? false,
    meta: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta || {},
    alias: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.alias || [],
    redirect: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.name ?? "Spa by location landing: 7",
    path: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.path ?? "/spa-balneario/alicante/l-alfas-del-pi/",
    props: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.props ?? false,
    meta: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta || {},
    alias: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.alias || [],
    redirect: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.name ?? "Spa by location landing: 8",
    path: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.path ?? "/spa-balneario/alicante/la-nucia/",
    props: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.props ?? false,
    meta: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta || {},
    alias: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.alias || [],
    redirect: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.name ?? "Spa by location landing: 9",
    path: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.path ?? "/spa-balneario/alicante/mutxamel/",
    props: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.props ?? false,
    meta: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta || {},
    alias: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.alias || [],
    redirect: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.name ?? "Spa by location landing: 10",
    path: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.path ?? "/spa-balneario/alicante/rojales/",
    props: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.props ?? false,
    meta: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta || {},
    alias: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.alias || [],
    redirect: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.name ?? "Spa by location landing: 11",
    path: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.path ?? "/spa-balneario/alicante/teulada/",
    props: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.props ?? false,
    meta: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta || {},
    alias: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.alias || [],
    redirect: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.name ?? "Spa by location landing: 12",
    path: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.path ?? "/spa-balneario/comunidad-valenciana/",
    props: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.props ?? false,
    meta: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta || {},
    alias: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.alias || [],
    redirect: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.name ?? "Spa by location landing: 13",
    path: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.path ?? "/spa-balneario/malaga/",
    props: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.props ?? false,
    meta: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta || {},
    alias: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.alias || [],
    redirect: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.name ?? "Spa by location landing: 14",
    path: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.path ?? "/spa-balneario/malaga/benalmadena/",
    props: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.props ?? false,
    meta: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta || {},
    alias: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.alias || [],
    redirect: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.name ?? "Spa by location landing: 15",
    path: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.path ?? "/spa-balneario/malaga/estepona/",
    props: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.props ?? false,
    meta: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta || {},
    alias: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.alias || [],
    redirect: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.name ?? "Spa by location landing: 16",
    path: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.path ?? "/spa-balneario/malaga/fuengirola/",
    props: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.props ?? false,
    meta: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta || {},
    alias: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.alias || [],
    redirect: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.name ?? "Spa by location landing: 17",
    path: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.path ?? "/spa-balneario/malaga/malaga/",
    props: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.props ?? false,
    meta: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta || {},
    alias: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.alias || [],
    redirect: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.name ?? "Spa by location landing: 18",
    path: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.path ?? "/spa-balneario/malaga/marbella/",
    props: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.props ?? false,
    meta: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta || {},
    alias: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.alias || [],
    redirect: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.name ?? "Spa by location landing: 19",
    path: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.path ?? "/spa-balneario/malaga/mijas/",
    props: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.props ?? false,
    meta: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta || {},
    alias: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.alias || [],
    redirect: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.name ?? "Spa by location landing: 20",
    path: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.path ?? "/spa-balneario/malaga/ronda/",
    props: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.props ?? false,
    meta: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta || {},
    alias: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.alias || [],
    redirect: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.name ?? "Spa by location landing: 21",
    path: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.path ?? "/spa-balneario/malaga/torremolinos/",
    props: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.props ?? false,
    meta: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta || {},
    alias: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.alias || [],
    redirect: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.name ?? "Spa by location landing: 22",
    path: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.path ?? "/spa-balneario/tenerife/",
    props: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.props ?? false,
    meta: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta || {},
    alias: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.alias || [],
    redirect: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.name ?? "Spa by location landing: 23",
    path: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.path ?? "/spa-balneario/tenerife/adeje/",
    props: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.props ?? false,
    meta: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta || {},
    alias: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.alias || [],
    redirect: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.name ?? "Spa by location landing: 24",
    path: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.path ?? "/spa-balneario/tenerife/arona/",
    props: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.props ?? false,
    meta: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta || {},
    alias: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.alias || [],
    redirect: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.name ?? "Spa by location landing: 25",
    path: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.path ?? "/spa-balneario/tenerife/guia-de-isora/",
    props: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.props ?? false,
    meta: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta || {},
    alias: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.alias || [],
    redirect: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.name ?? "Spa by location landing: 26",
    path: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.path ?? "/spa-balneario/tenerife/playa-de-las-americas/",
    props: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.props ?? false,
    meta: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta || {},
    alias: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.alias || [],
    redirect: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.name ?? "Spa by location landing: 27",
    path: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.path ?? "/spa-balneario/tenerife/puerto-de-la-cruz/",
    props: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.props ?? false,
    meta: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta || {},
    alias: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.alias || [],
    redirect: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.name ?? "Spa by location landing: 28",
    path: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.path ?? "/spa-balneario/tenerife/san-cristobal-de-la-laguna/",
    props: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.props ?? false,
    meta: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta || {},
    alias: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.alias || [],
    redirect: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.name ?? "Spa by location landing: 29",
    path: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.path ?? "/spa-balneario/tenerife/san-miguel-de-abona/",
    props: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.props ?? false,
    meta: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta || {},
    alias: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.alias || [],
    redirect: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.name ?? "Spa by location landing: 30",
    path: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.path ?? "/spa-balneario/tenerife/santa-cruz-de-tenerife/",
    props: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.props ?? false,
    meta: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta || {},
    alias: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.alias || [],
    redirect: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.name ?? "Spa by location landing: 31",
    path: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.path ?? "/spa-balneario/tenerife/santa-ursula/",
    props: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.props ?? false,
    meta: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta || {},
    alias: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.alias || [],
    redirect: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.name ?? "Spa by location landing: 32",
    path: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.path ?? "/spa-balneario/valencia/",
    props: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.props ?? false,
    meta: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta || {},
    alias: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.alias || [],
    redirect: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.name ?? "Spa by location landing: 33",
    path: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.path ?? "/spa-balneario/valencia/gandia/",
    props: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.props ?? false,
    meta: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta || {},
    alias: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.alias || [],
    redirect: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.name ?? "Spa by location landing: 34",
    path: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.path ?? "/spa-balneario/valencia/paterna/",
    props: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.props ?? false,
    meta: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta || {},
    alias: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.alias || [],
    redirect: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.name ?? "Spa by location landing: 35",
    path: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.path ?? "/spa-balneario/valencia/valencia/",
    props: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.props ?? false,
    meta: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta || {},
    alias: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.alias || [],
    redirect: SpaList_46pageDUFXoh6aUnoWe03FqNm6rQ9vSliCAkkZDIqBEnrozX8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaList.page.vue")
  },
  {
    name: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.name ?? "Spa landing: 0",
    path: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/",
    props: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.props ?? false,
    meta: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta || {},
    alias: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.alias || [],
    redirect: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.name ?? "Spa landing: 1",
    path: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/",
    props: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.props ?? false,
    meta: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta || {},
    alias: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.alias || [],
    redirect: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.name ?? "Spa landing: 2",
    path: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.path ?? "/spa-balneario/malaga/spa-the-flag/",
    props: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.props ?? false,
    meta: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta || {},
    alias: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.alias || [],
    redirect: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.name ?? "Spa landing: 3",
    path: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.path ?? "/spa-balneario/alicante/spa-roca-esmeralda/",
    props: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.props ?? false,
    meta: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta || {},
    alias: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.alias || [],
    redirect: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.name ?? "Spa landing: 4",
    path: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.path ?? "/spa-balneario/malaga/higueron-spa/",
    props: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.props ?? false,
    meta: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta || {},
    alias: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.alias || [],
    redirect: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.name ?? "Spa landing: 5",
    path: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/",
    props: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.props ?? false,
    meta: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta || {},
    alias: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.alias || [],
    redirect: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.name ?? "Spa landing: 6",
    path: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.path ?? "/spa-balneario/tenerife/spa-spacio-10-marylanza-suites/",
    props: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.props ?? false,
    meta: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta || {},
    alias: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.alias || [],
    redirect: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.name ?? "Spa landing: 7",
    path: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.path ?? "/spa-balneario/tenerife/nammu-spa-area-vincci-la-plantacion-del-sur/",
    props: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.props ?? false,
    meta: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta || {},
    alias: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.alias || [],
    redirect: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.name ?? "Spa landing: 8",
    path: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/",
    props: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.props ?? false,
    meta: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta || {},
    alias: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.alias || [],
    redirect: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.name ?? "Spa landing: 9",
    path: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/",
    props: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.props ?? false,
    meta: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta || {},
    alias: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.alias || [],
    redirect: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.name ?? "Spa landing: 10",
    path: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.path ?? "/spa-balneario/valencia/spa-bodyna-palau-de-mar-valencia/",
    props: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.props ?? false,
    meta: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta || {},
    alias: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.alias || [],
    redirect: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.name ?? "Spa landing: 11",
    path: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.path ?? "/spa-balneario/tenerife/anantara-spa-tenerife/",
    props: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.props ?? false,
    meta: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta || {},
    alias: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.alias || [],
    redirect: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.name ?? "Spa landing: 12",
    path: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.path ?? "/spa-balneario/alicante/santuari-urban-spa/",
    props: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.props ?? false,
    meta: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta || {},
    alias: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.alias || [],
    redirect: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.name ?? "Spa landing: 13",
    path: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.path ?? "/spa-balneario/tenerife/spa-wellness-canarias/",
    props: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.props ?? false,
    meta: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta || {},
    alias: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.alias || [],
    redirect: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.name ?? "Spa landing: 14",
    path: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/",
    props: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.props ?? false,
    meta: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta || {},
    alias: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.alias || [],
    redirect: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.name ?? "Spa landing: 15",
    path: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.path ?? "/spa-balneario/tenerife/spa-azules-nivaria/",
    props: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.props ?? false,
    meta: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta || {},
    alias: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.alias || [],
    redirect: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.name ?? "Spa landing: 16",
    path: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/",
    props: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.props ?? false,
    meta: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta || {},
    alias: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.alias || [],
    redirect: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.name ?? "Spa landing: 17",
    path: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/",
    props: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.props ?? false,
    meta: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta || {},
    alias: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.alias || [],
    redirect: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.name ?? "Spa landing: 18",
    path: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.path ?? "/spa-balneario/alicante/luxor-spa/",
    props: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.props ?? false,
    meta: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta || {},
    alias: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.alias || [],
    redirect: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.name ?? "Spa landing: 19",
    path: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural-spa-gala-alexandre/",
    props: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.props ?? false,
    meta: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta || {},
    alias: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.alias || [],
    redirect: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.name ?? "Spa landing: 20",
    path: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/",
    props: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.props ?? false,
    meta: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta || {},
    alias: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.alias || [],
    redirect: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.name ?? "Spa landing: 21",
    path: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.path ?? "/spa-balneario/malaga/alum-spa-aguamarina-suites/",
    props: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.props ?? false,
    meta: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta || {},
    alias: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.alias || [],
    redirect: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.name ?? "Spa landing: 22",
    path: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/",
    props: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.props ?? false,
    meta: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta || {},
    alias: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.alias || [],
    redirect: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.name ?? "Spa landing: 23",
    path: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.path ?? "/spa-balneario/malaga/calma-spa/",
    props: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.props ?? false,
    meta: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta || {},
    alias: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.alias || [],
    redirect: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.name ?? "Spa landing: 24",
    path: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.path ?? "/spa-balneario/alicante/wellness-beauty-bali-center/",
    props: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.props ?? false,
    meta: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta || {},
    alias: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.alias || [],
    redirect: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.name ?? "Spa landing: 25",
    path: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/",
    props: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.props ?? false,
    meta: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta || {},
    alias: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.alias || [],
    redirect: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.name ?? "Spa landing: 26",
    path: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.path ?? "/spa-balneario/malaga/spa-hotel-monarque-fuengirola-park/",
    props: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.props ?? false,
    meta: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta || {},
    alias: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.alias || [],
    redirect: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.name ?? "Spa landing: 27",
    path: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.path ?? "/spa-balneario/tenerife/the-ritz-carlton-spa/",
    props: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.props ?? false,
    meta: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta || {},
    alias: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.alias || [],
    redirect: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.name ?? "Spa landing: 28",
    path: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/",
    props: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.props ?? false,
    meta: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta || {},
    alias: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.alias || [],
    redirect: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.name ?? "Spa landing: 29",
    path: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/",
    props: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.props ?? false,
    meta: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta || {},
    alias: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.alias || [],
    redirect: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.name ?? "Spa landing: 30",
    path: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.path ?? "/spa-balneario/malaga/las-dunas-spa/",
    props: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.props ?? false,
    meta: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta || {},
    alias: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.alias || [],
    redirect: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.name ?? "Spa landing: 31",
    path: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.path ?? "/spa-balneario/malaga/alum-spa-maestranza/",
    props: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.props ?? false,
    meta: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta || {},
    alias: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.alias || [],
    redirect: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.name ?? "Spa landing: 32",
    path: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.path ?? "/spa-balneario/malaga/alum-spa-amaragua/",
    props: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.props ?? false,
    meta: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta || {},
    alias: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.alias || [],
    redirect: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.name ?? "Spa landing: 33",
    path: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/",
    props: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.props ?? false,
    meta: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta || {},
    alias: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.alias || [],
    redirect: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.name ?? "Spa landing: 34",
    path: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/",
    props: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.props ?? false,
    meta: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta || {},
    alias: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.alias || [],
    redirect: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.name ?? "Spa landing: 35",
    path: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/",
    props: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.props ?? false,
    meta: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta || {},
    alias: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.alias || [],
    redirect: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.name ?? "Spa landing: 36",
    path: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.path ?? "/spa-balneario/valencia/spa-boutique-estimar-valencia-4one/",
    props: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.props ?? false,
    meta: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta || {},
    alias: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.alias || [],
    redirect: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.name ?? "Spa landing: 37",
    path: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.path ?? "/spa-balneario/valencia/naital-spa-wellness/",
    props: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.props ?? false,
    meta: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta || {},
    alias: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.alias || [],
    redirect: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.name ?? "Spa landing: 38",
    path: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.path ?? "/spa-balneario/alicante/spa-by-serawa-moraira/",
    props: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.props ?? false,
    meta: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta || {},
    alias: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.alias || [],
    redirect: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.name ?? "Spa landing: 39",
    path: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.path ?? "/spa-balneario/valencia/hotel-neptuno-spa/",
    props: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.props ?? false,
    meta: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta || {},
    alias: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.alias || [],
    redirect: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.name ?? "Spa landing: 40",
    path: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/",
    props: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.props ?? false,
    meta: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta || {},
    alias: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.alias || [],
    redirect: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.name ?? "Spa landing: 41",
    path: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.path ?? "/spa-balneario/malaga/hard-rock-spa-marbella/",
    props: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.props ?? false,
    meta: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta || {},
    alias: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.alias || [],
    redirect: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.name ?? "Spa landing: 42",
    path: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/",
    props: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.props ?? false,
    meta: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta || {},
    alias: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.alias || [],
    redirect: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.name ?? "Spa landing: 43",
    path: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/",
    props: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.props ?? false,
    meta: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta || {},
    alias: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.alias || [],
    redirect: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.name ?? "Spa landing: 44",
    path: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.path ?? "/spa-balneario/tenerife/centro-wellness-nirama-by-baobab-suites/",
    props: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.props ?? false,
    meta: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta || {},
    alias: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.alias || [],
    redirect: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.name ?? "Spa landing: 45",
    path: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.path ?? "/spa-balneario/malaga/spa-del-palacete/",
    props: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.props ?? false,
    meta: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta || {},
    alias: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.alias || [],
    redirect: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.name ?? "Spa landing: 46",
    path: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.path ?? "/spa-balneario/tenerife/sandara-wellness-spa/",
    props: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.props ?? false,
    meta: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta || {},
    alias: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.alias || [],
    redirect: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.name ?? "Spa landing: 47",
    path: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/",
    props: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.props ?? false,
    meta: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta || {},
    alias: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.alias || [],
    redirect: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.name ?? "Spa landing: 48",
    path: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.path ?? "/spa-balneario/malaga/hydros-spa/",
    props: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.props ?? false,
    meta: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta || {},
    alias: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.alias || [],
    redirect: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.name ?? "Spa landing: 49",
    path: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/",
    props: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.props ?? false,
    meta: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta || {},
    alias: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.alias || [],
    redirect: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.name ?? "Spa landing: 50",
    path: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/",
    props: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.props ?? false,
    meta: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta || {},
    alias: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.alias || [],
    redirect: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.name ?? "Spa landing: 51",
    path: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/",
    props: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.props ?? false,
    meta: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta || {},
    alias: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.alias || [],
    redirect: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.name ?? "Spa landing: 52",
    path: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.path ?? "/spa-balneario/tenerife/naiad-wellness-center/",
    props: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.props ?? false,
    meta: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta || {},
    alias: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.alias || [],
    redirect: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.name ?? "Spa landing: 53",
    path: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/",
    props: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.props ?? false,
    meta: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta || {},
    alias: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.alias || [],
    redirect: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.name ?? "Spa landing: 54",
    path: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.path ?? "/spa-balneario/malaga/spa-sensations-marbella-coral-beach/",
    props: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.props ?? false,
    meta: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta || {},
    alias: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.alias || [],
    redirect: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.name ?? "Spa landing: 55",
    path: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.path ?? "/spa-balneario/tenerife/bio-spa-costa-adeje/",
    props: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.props ?? false,
    meta: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta || {},
    alias: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.alias || [],
    redirect: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.name ?? "Spa landing: 56",
    path: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/",
    props: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.props ?? false,
    meta: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta || {},
    alias: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.alias || [],
    redirect: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.name ?? "Spa landing: 57",
    path: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.path ?? "/spa-balneario/malaga/mood-spa/",
    props: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.props ?? false,
    meta: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta || {},
    alias: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.alias || [],
    redirect: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.name ?? "Spa landing: 58",
    path: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/",
    props: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.props ?? false,
    meta: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta || {},
    alias: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.alias || [],
    redirect: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.name ?? "Spa landing: 59",
    path: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/",
    props: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.props ?? false,
    meta: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta || {},
    alias: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.alias || [],
    redirect: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.name ?? "Spa landing: 60",
    path: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/",
    props: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.props ?? false,
    meta: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta || {},
    alias: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.alias || [],
    redirect: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.name ?? "Spa landing: 61",
    path: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/",
    props: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.props ?? false,
    meta: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta || {},
    alias: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.alias || [],
    redirect: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.name ?? "Spa landing: 62",
    path: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-mirador/",
    props: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.props ?? false,
    meta: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta || {},
    alias: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.alias || [],
    redirect: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.name ?? "Spa landing: 63",
    path: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.path ?? "/spa-balneario/tenerife/body-care-labranda-spa-wellness/",
    props: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.props ?? false,
    meta: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta || {},
    alias: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.alias || [],
    redirect: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.name ?? "Spa landing: 64",
    path: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/",
    props: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.props ?? false,
    meta: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta || {},
    alias: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.alias || [],
    redirect: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.name ?? "Spa landing: 65",
    path: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.path ?? "/spa-balneario/tenerife/bio-spa-fanabe/",
    props: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.props ?? false,
    meta: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta || {},
    alias: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.alias || [],
    redirect: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.name ?? "Spa landing: 66",
    path: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.path ?? "/spa-balneario/alicante/spa-sandos-monaco/",
    props: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.props ?? false,
    meta: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta || {},
    alias: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.alias || [],
    redirect: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.name ?? "Spa landing: 67",
    path: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.path ?? "/spa-balneario/valencia/azz-valencia-congress-spa/",
    props: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.props ?? false,
    meta: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta || {},
    alias: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.alias || [],
    redirect: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.name ?? "Spa landing: 68",
    path: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/",
    props: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.props ?? false,
    meta: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta || {},
    alias: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.alias || [],
    redirect: SpaDetail_46paged6_Fx_piRHuvW12pMgTskgXpRULb36SmCNtzrtZ_45Jz0Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/spa/SpaDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 0",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/mencey-signature-ritual-115-min-con-circuito-termal/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 1",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/masaje-antiestres-aromatico-45-min-con-circuito-termal/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 2",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/tratamiento-facial-hidraforce-source-marine-55min-con-circuito-termal/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 3",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/serenity-love/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 4",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/family-day-25-min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 5",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/mencey-signature-massage-55-min-con-circuito-termal/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 6",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/circuito-termal-doble/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 7",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/gastrospa-lunes-a-viernes/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 8",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/circuito-termal-individual/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 9",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/stone-therapy-55-con-circuito-termal/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 10",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/sprunch-con-circuito-termal-sabados-y-domingos/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 11",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/lomi-lomi-nui-55-min-con-circuito-termal/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 12",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/masaje-antiestres-55-min-con-circuito-termal/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 13",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/after-work-30-min-masaje-con-circuito-termal-lunes-a-jueves/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 14",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/senses-night/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 15",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/manicura-y-pedicura-express-con-acceso-a-spa/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 16",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/iberostar-vegan-facial-55-min-con-circuito-termal/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 17",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/brunch-sin-circuito-termal-sabados-y-domingos/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 18",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/masaje-podal-oriental/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 19",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/terapia-de-perla-y-oro/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 20",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/reina-de-egipto/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 21",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/masaje-samay-30min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 22",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/spa-privado-vip-masaje-para-2/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 23",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/masaje-prenatal/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 24",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/masaje-espalda/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 25",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/capuccino/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 26",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/masaje-equilibrante-shiatsu/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 27",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/masaje-tailandes/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 28",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/masaje-cuatro-manos/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 29",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/luz-del-mediterraneo/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 30",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/exotico-ceilan/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 31",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/piedras-de-yucamani/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 32",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/hidromasaje-de-esencias/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 33",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/spa-the-flag/masaje-relajante-de-cabeza-cuello-y-hombros/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 34",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/spa-the-flag/masaje-aromatico-50-min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 35",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/spa-the-flag/piel-de-seda/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 36",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/spa-the-flag/body-wrap/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 37",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/spa-the-flag/terapia-geotermal-con-piedras/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 38",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/spa-the-flag/masaje-relajante-de-pies/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 39",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/spa-the-flag/el-arte-de-crear/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 40",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/spa-the-flag/masaje-dinamico-de-recuperacion-corporal/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 41",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/spa-the-flag/masaje-terapeutico-50-min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 42",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/spa-the-flag/tratamiento-de-limpieza-facial-purificante/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 43",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/spa-the-flag/peeling-corporal-con-cristales-marinos/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 44",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/spa-the-flag/ritual-anti-stress/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 45",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-roca-esmeralda/prenatal-y-posnatal/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 46",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-roca-esmeralda/esencias-y-sentidos/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 47",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-roca-esmeralda/circuito-spa-nino/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 48",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-roca-esmeralda/chocolate/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 49",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-roca-esmeralda/barro/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 50",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-roca-esmeralda/masaje-podal/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 51",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-roca-esmeralda/masaje-corporal-ayurveda/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 52",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-roca-esmeralda/hydraluronic/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 53",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-roca-esmeralda/masaje-hawaiano/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 54",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-roca-esmeralda/circuito-spa-adulto/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 55",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/higueron-spa/baneras-de-inmersion-en-hielo/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 56",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/higueron-spa/masaje-platinum-beach/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 57",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/higueron-spa/tarjeta-regalo-importe-200e/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 58",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/higueron-spa/pack-banera-de-inmersion-en-hielo-masaje-deportivo-25-min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 59",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/higueron-spa/pack-circuito-spa-con-cava-y-bombones/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 60",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/higueron-spa/circuito-spa/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 61",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/higueron-spa/pack-presoterapia/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 62",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/higueron-spa/pack-banera-de-inmersion-en-hielo-spa/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 63",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/higueron-spa/pack-experiencia-relax-unica-spa-masaje-relax-50/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 64",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/higueron-spa/pack-experiencia-duo-80/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 65",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/higueron-spa/pack-banera-de-inmersion-en-hielo-masaje-deportivo-25-min-spa/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 66",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/higueron-spa/pack-experiencia-duo-romantico/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 67",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/higueron-spa/pack-experiencia-hidratacion-profunda-spa-tratamiento-facial-de-acido-hialuronico/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 68",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/higueron-spa/masaje-relajante-aromatico-25-min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 69",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/higueron-spa/masaje-relajante-aromatico-50-min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 70",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/higueron-spa/facial-anti-aging-de-acido-hialuronico/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 71",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/higueron-spa/masaje-piedras-calientes/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 72",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/antiestres-masaje/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 73",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/circuito-spa-10-sesiones/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 74",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/antiestres-masaje-doble-spa/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 75",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/anthelia-signature-massage-spa/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 76",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/circuito-hydrowellness-1/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 77",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/oriental-55-min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 78",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/especifico-localizado/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 79",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/gastro-spa-circuito-termal-almuerzo/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 80",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/gastro-spa-circuito-termal-masaje-almuerzo/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 81",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/spa-en-familia/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 82",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/sprunch-spa-brunch/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 83",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/masaje-piedras-calientes/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 84",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/momento-spa-masaje/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 85",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-spacio-10-marylanza-suites/circuito-spa-acceso-gimnasio/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 86",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-spacio-10-marylanza-suites/circuito-spa/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 87",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-spacio-10-marylanza-suites/masaje-relajante-aromaterapia/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 88",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-spacio-10-marylanza-suites/tratamiento-facial-personalizado/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 89",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-spacio-10-marylanza-suites/peeling-exfoliante-corporal-piel-de-seda-40mins/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 90",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-spacio-10-marylanza-suites/pack-bienestar-para-dos-personas/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 91",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-spacio-10-marylanza-suites/masaje-completo-muscular-profundo/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 92",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-spacio-10-marylanza-suites/masaje-terapeutico-espalda-cuello-y-hombro/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 93",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/nammu-spa-area-vincci-la-plantacion-del-sur/masaje-personalizado-50/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 94",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/nammu-spa-area-vincci-la-plantacion-del-sur/drenaje-linfatico/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 95",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/nammu-spa-area-vincci-la-plantacion-del-sur/masaje-personalizado-25/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 96",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/nammu-spa-area-vincci-la-plantacion-del-sur/piel-de-seda/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 97",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/nammu-spa-area-vincci-la-plantacion-del-sur/reflexologia-podal/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 98",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/nammu-spa-area-vincci-la-plantacion-del-sur/spa-relax-2-horas/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 99",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/nammu-spa-area-vincci-la-plantacion-del-sur/circuito-thalassa-1-hora/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 100",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/nammu-spa-area-vincci-la-plantacion-del-sur/ritual-vincci-sun/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 101",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/nammu-spa-area-vincci-la-plantacion-del-sur/masaje-40-min-y-spa-2-horas/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 102",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/nammu-spa-area-vincci-la-plantacion-del-sur/masaje-20-min-y-spa-2-horas/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 103",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/nammu-spa-area-vincci-la-plantacion-del-sur/masaje-celta-de-piedras-calientes/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 104",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/nammu-spa-area-vincci-la-plantacion-del-sur/masaje-abhyanga/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 105",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/circuito-spa-almuerzo-o-cena-fines-de-semana-y-dias-especiales/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 106",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/masaje-local-o-craneo-facial/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 107",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/tratamiento-mousse-de-chocolate/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 108",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/marine-spa/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 109",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/albir-relax/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 110",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/circuito-spa-desayuno-buffet-fines-de-semana-y-dias-especiales/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 111",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/masaje-con-canas-de-bambu/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 112",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/senses-detox/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 113",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/masaje-aromaterapia/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 114",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/vip-senses/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 115",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/masaje-relajante-general/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 116",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/circuito-spa/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 117",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/circuito-spa-ninos/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 118",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/circuito-spa-desayuno-buffet-martes-a-viernes/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 119",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/circuito-spa-almuerzo-o-cena-martes-a-viernes/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 120",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/circuito-spa-para-2/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 121",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/masaje-geotermal/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 122",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/masaje-relax-de-rostro-cuello-y-escote-beer-1/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 123",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/masaje-exclusivity-de-cerveza-55min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 124",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/circuito-beer-spa-en-sala-comun-pareja-1/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 125",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/circuito-beer-spa-con-cata-y-maridaje/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 126",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/circuito-beer-spa-masaje-15-minutos-residente-canario-justificante-de-residencia-obligatorio/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 127",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/circuito-beer-spa-masaje-15-minutos-pareja-residente-canario-justificante-de-residencia-obligatorio/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 128",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/masaje-exclusivity-de-cerveza-55min-residente-canario-justificante-de-residencia-obligatorio/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 129",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/circuito-beer-spa-masaje-25-minutos-pareja-residente-canario-justificante-de-residencia-obligatorio/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 130",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/masaje-exclusivity-de-cerveza-55min-pareja-residente-canario-justificante-de-residencia-obligatorio/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 131",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/circuito-beer-spa-pareja-residente-canario-justificante-de-residencia-obligatorio/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 132",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/circuito-beer-spa-residente-canario-justificante-de-residencia-obligatorio/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 133",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/circuito-beer-spa-masaje-55-minutos-pareja-residente-canario-justificante-de-residencia-obligatorio/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 134",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/circuito-beer-spa-masaje-15-minutos-sala-comun-1-persona/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 135",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/circuito-beer-spa-masaje-localizado-25min-sala-comun-pareja/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 136",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/circuito-beer-spa-masaje-15-minutos-sala-comun-pareja/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 137",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/circuito-beer-spa-en-sala-comun/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 138",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/masaje-exclusivity-de-cerveza-25min-pareja/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 139",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/circuito-vip-en-sala-privada-pareja/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 140",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/circuito-premium-en-sala-privada-pareja/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 141",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/spa-bodyna-palau-de-mar-valencia/masaje-tango/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 142",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/spa-bodyna-palau-de-mar-valencia/masaje-de-piedras-calientes/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 143",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/spa-bodyna-palau-de-mar-valencia/masaje-sensorial-de-cabeza-y-cuero-cabelludo/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 144",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/spa-bodyna-palau-de-mar-valencia/masaje-oriental-spa-1-pax/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 145",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/spa-bodyna-palau-de-mar-valencia/masaje-naranja-y-acceso-spa/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 146",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/spa-bodyna-palau-de-mar-valencia/relajese-disfrute/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 147",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/spa-bodyna-palau-de-mar-valencia/masaje-tango-y-acceso-spa/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 148",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/spa-bodyna-palau-de-mar-valencia/masaje-hawaiano-lomi-lomi/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 149",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/spa-bodyna-palau-de-mar-valencia/masaje-balines-envolvente/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 150",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/spa-bodyna-palau-de-mar-valencia/relajese-y-disfrute-2-pax/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 151",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/spa-bodyna-palau-de-mar-valencia/momento-spa-masaje/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 152",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/spa-bodyna-palau-de-mar-valencia/ritual-de-oriente-50/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 153",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/spa-bodyna-palau-de-mar-valencia/masaje-naranja-y-acceso-al-circuito-spa-1persona/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 154",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/spa-bodyna-palau-de-mar-valencia/spa-para-dos-2-pax/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 155",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/anantara-spa-tenerife/de-este-a-oeste/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 156",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/anantara-spa-tenerife/masaje-relajante/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 157",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/anantara-spa-tenerife/masaje-anantara/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 158",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/anantara-spa-tenerife/tiempo-para-dos/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 159",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/anantara-spa-tenerife/masaje-en-pareja/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 160",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/anantara-spa-tenerife/oasis-de-serenidad/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 161",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/anantara-spa-tenerife/masaje-tradicional-tailandes/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 162",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/anantara-spa-tenerife/masaje-con-piedras-calientes/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 163",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/anantara-spa-tenerife/masaje-muscular-profundo/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 164",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/anantara-spa-tenerife/dulce-espera/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 165",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/anantara-spa-tenerife/facial-personalizado/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 166",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/anantara-spa-tenerife/experiencia-anantara-con-masaje-y-spa/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 167",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/anantara-spa-tenerife/viaje-a-traves-de-los-sentidos/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 168",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/anantara-spa-tenerife/circuito-spa/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 169",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/anantara-spa-tenerife/circuito-spa-residente-canario/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 170",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/santuari-urban-spa/limpieza-profunda-con-hidromass/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 171",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/santuari-urban-spa/higiene-facial-expres/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 172",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/santuari-urban-spa/masaje-con-chocolaterapia-60-min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 173",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/santuari-urban-spa/ritual-lirio/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 174",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/santuari-urban-spa/ritual-jade/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 175",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/santuari-urban-spa/masaje-relajante-30-min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 176",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/santuari-urban-spa/exfoliacion-corporal-hidratacion/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 177",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/santuari-urban-spa/ritual-orquidea/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 178",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/santuari-urban-spa/masaje-con-piedras-calientes-60-min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 179",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/santuari-urban-spa/masaje-descontracturante-30-min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 180",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/santuari-urban-spa/ritual-loto/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 181",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-canarias/facial-especializado/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 182",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-canarias/masaje-ayurveda/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 183",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-canarias/spa-privado-para-2/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 184",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-canarias/masaje-anti-dolor-50/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 185",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-canarias/masaje-lomi-lomi-50/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 186",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-canarias/masaje-en-pareja-50/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 187",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-canarias/spa-privado-masaje-aromaterapia/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 188",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-canarias/masaje-aromaterapia-30/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 189",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-canarias/masaje-aromaterapia-50/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 190",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-canarias/reflexologia-30/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 191",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-canarias/facial-express/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 192",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-canarias/spa-privado-y-moet-para-2/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 193",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-canarias/masaje-muscular-profundo-30/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 194",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-canarias/masaje-piernas-cansadas-30/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 195",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-canarias/masaje-craneal-de-la-india/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 196",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-canarias/masaje-con-piedras-calientes/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 197",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/masaje-de-pindas-con-extracto-de-cerveza-completo/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 198",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-beer-spa-masaje-completo-55min-sala-comun-pareja/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 199",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-beer-spa-masaje-15-minutos-sala-comun-1-persona/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 200",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-beer-spa-masaje-completo-55min-sala-comun-1-persona/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 201",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-beer-spa-masaje-15-minutos-sala-comun-pareja/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 202",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-beer-spa-en-sala-comun-pareja/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 203",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/masaje-exclusivity-de-cerveza-55min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 204",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/masaje-exclusivity-de-cerveza-55min-pareja/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 205",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-beer-spa-en-sala-comun/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 206",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-beer-spa-estudiantes/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 207",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-beer-spa-con-cata-y-maridaje/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 208",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/masaje-relax-de-rostro-cuello-y-escote-beer/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 209",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/masaje-holistico-con-aceite-de-cerveza-parcial/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 210",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-beer-spa-beauty/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 211",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-premium-en-sala-privada-pareja/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 212",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-vip-en-sala-privada-pareja/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 213",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-azules-nivaria/ritual-facial-y-masaje/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 214",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-azules-nivaria/circuito-spa-ritual-para-2/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 215",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-azules-nivaria/circuito-spa-masaje-para-2/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 216",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-azules-nivaria/circuito-spa/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 217",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-azules-nivaria/circuito-spa-para-2/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 218",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/spa-privado-desde-1-persona-a-12-circuito-spa-de-90min-privado-incluye-plato-de-fruta-y-copa-de-cava-1/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 219",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/spa-privado-desde-1-persona-a-12-circuito-spa-de-90min-privado-incluye-plato-de-fruta-y-copa-de-cava/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 220",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/masaje-relax-con-vela-aromatica-de-75-min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 221",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/ritual-beauty-tratamiento-facial-55/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 222",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/experiencia-bienestar-con-vela-circuito-spa-90min-masaje-relajante-con-vela-aromatica-de-75min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 223",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/experiencia-serenity-circuito-spa-90min-exfoliacion-corporal-masaje-oriental-50min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 224",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/experiencia-buenos-dias-desayuno-buffet-circuito-spa-90min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 225",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/experiencia-gastro-relax-comida-cena-agua-y-vino-de-la-casa-incl-circuito-spa90min-buffet-o-menu-segun-ocupacion/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 226",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/pack-parejas-experiencia-arrocera-spa-relax-menu-arrocero-agua-y-vino-de-la-casa-incl-circuito-spa-de-90min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 227",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/experiencia-renovadora-circuito-spa-de-90min-limpieza-facial-basica-masaje-orient-50/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 228",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/experiencia-arrocera-spa-relax-supreme-minimo-2-personas-menu-arrocero-circuito-spa-de-90min-masaje-oriental-25min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 229",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/pack-parejas-experiencia-bienestar-circuito-spa-90min-masaje-oriental-50min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 230",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/pack-parejas-experiencia-arrocera-spa-relax-supreme-menu-arrocero-circuito-spa-de-90min-masaje-oriental-25min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 231",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/experiencia-envolvente-circuito-spa-90min-ritual-corporal-con-exfoliacion-y-envolturalimpieza-facial-basica/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 232",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/pack-parejas-experiencia-serenitycircuito-spa-90min-exfoliacion-corporal-masaje-oriental-50min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 233",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/experiencia-arrocera-spa-relax-min-2-personas-menu-arrocero-agua-y-vino-de-la-casa-incl-circuito-spa-de-90min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 234",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/experiencia-bienestar-circuito-spa-90min-masaje-oriental-50min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 235",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/masaje-oriental-de-50-min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 236",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/masaje-oriental-de-25-min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 237",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/experiencia-relax-circuito-spa-90min-masaje-oriental-25min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 238",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/circuito-spa-de-90min-a-partir-de-15-anos/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 239",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/pack-parejas-experiencia-relax-circuito-spa-90min-masaje-oriental-25min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 240",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/masaje-holistico-de-piedras-25-min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 241",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/hidratante-hialuronico/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 242",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/firmeza/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 243",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/duo-reafirmante-facial-y-corporal/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 244",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/ritual-relajante-aromatico/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 245",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/masaje-relajante/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 246",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/despayuno-privado-para-2/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 247",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/royal-spa-circuito-de-aguas-privado-para-2/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 248",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/spa-buffet-privado-para-2/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 249",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/spa-almuerzo-privado-para-2/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 250",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/royal-spa-circuito-privado-para-2-90/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 251",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/sprunch-con-spa-privado-para-1/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 252",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/spa-cena-privado-para-2/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 253",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/spa-almuerzo/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 254",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/classic-spa-circuito-de-aguas-90/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 255",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/escapada-romantica-privada-para-2/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 256",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/spa-cena/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 257",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/sprunch-spa-brunch-1/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 258",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/spa-buffet/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 259",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/despayuno/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 260",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/spa-en-familia-para-3-personas/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 261",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/evasion/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 262",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/masaje-muscular-profundo/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 263",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/masaje-personalizado/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 264",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/masaje-localizado/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 265",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/ritual-canario/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 266",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/luxor-spa/masaje-con-piedras-calientes-volcanicas-o-pindas-aromaticas-individual/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 267",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/luxor-spa/tratamiento-facial-antioxidante-de-vitamina-c/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 268",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/luxor-spa/masaje-relajante-local-deportivo-o-terapeutico-25-min-individual/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 269",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/luxor-spa/masaje-con-piedras-calientes-volcanicas-o-pindas-aromaticas-pareja/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 270",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/luxor-spa/masaje-relajante-local-deportivo-o-terapeutico-25-min-pareja/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 271",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/luxor-spa/chocolaterapia-pareja/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 272",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/luxor-spa/spa-privado-mas-masaje-y-cena/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 273",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/luxor-spa/spa-privado-mas-masaje/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 274",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/luxor-spa/spa-mas-desayuno-buffet-con-masaje/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 275",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/luxor-spa/spa-privado-mas-cena/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 276",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/luxor-spa/spa-privado/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 277",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/luxor-spa/spa-mas-cena-buffet/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 278",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/luxor-spa/circuito-spa-clientes-externos/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 279",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/luxor-spa/spa-mas-desayuno-buffet/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 280",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/luxor-spa/experiencia-en-jaccuzi-individual/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 281",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural-spa-gala-alexandre/circuito-termal-masaje/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 282",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural-spa-gala-alexandre/masaje-neurosedante-con-aromaterapia-relajante/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 283",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural-spa-gala-alexandre/tratamiento-facial-colageno-marino-ojos-incluidos-natura-bisse/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 284",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural-spa-gala-alexandre/tratamiento-facial-lifting-alisa-rellena-tensa-silicium-marino/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 285",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural-spa-gala-alexandre/tratamiento-facial-cocoon-facial-anti-polucion/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 286",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural-spa-gala-alexandre/tratamiento-facial-hyaluronico-marino-anti-edad-activador-de-juventud/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 287",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural-spa-gala-alexandre/bamboo-scrub-peeling-hidratacion-corporal/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 288",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural-spa-gala-alexandre/masaje-oro-y-aceites-esenciales-relajantes-50-min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 289",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural-spa-gala-alexandre/circuito-termal-gastronomia-masaje/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 290",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural-spa-gala-alexandre/masaje-espalda-cuello-25-min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 291",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural-spa-gala-alexandre/circuito-termal-gastronomia/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 292",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural-spa-gala-alexandre/circuito-termal/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 293",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural-spa-gala-alexandre/tratamiento-anticelulitis-masaje-envoltura-anticelulitica/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 294",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural-spa-gala-alexandre/masaje-terapeutico-deportivo-25-min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 295",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural-spa-gala-alexandre/masaje-rosa-terapia/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 296",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/vedic-massage-60-min-thai-room/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 297",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/futura-mama-60-min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 298",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/ritual-hammam/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 299",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/ritual-de-aloe-vera-y-hojas-de-platanera/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 300",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/detox-oxigenante-60min-biologique-recherche/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 301",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/masaje-en-pareja-60-minutos/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 302",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/masaje-relajante-60-min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 303",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/day-pass-circuito-spa-day/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 304",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/day-pass-spa-almuerzo/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 305",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/day-pass-circuito-spa-residente-canario/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 306",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/day-pass-spa-brunch-by-bahia-del-duque/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 307",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/masaje-energizante-60-min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 308",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/alum-spa-aguamarina-suites/aire-de-hadas/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 309",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/alum-spa-aguamarina-suites/circuito-spa-ritual/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 310",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/alum-spa-aguamarina-suites/circuito-spa-masaje-50/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 311",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/alum-spa-aguamarina-suites/masaje-personalizado-50/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 312",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/alum-spa-aguamarina-suites/circuito-spa-masaje-25/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 313",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/alum-spa-aguamarina-suites/masaje-personalizado-25/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 314",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/alum-spa-aguamarina-suites/passage-du-silence/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 315",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/ritual-radiance-c/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 316",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/bono-4-sesiones-circuito-spa-adulto/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 317",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/velada-romantica/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 318",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/bono-10-sesiones-circuito-spa-adulto/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 319",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/circuito-spa-adulto-1/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 320",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/ritual-hydraluronic/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 321",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/ritual-pureza-1/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 322",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/masaje-relajante-50-min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 323",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/experiencia-sun-repair/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 324",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/circuito-spa-menores-4-17-anos/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 325",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/deep-tissue-masaje-localizado/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 326",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/ritual-brisa-de-ganesha/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 327",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/ritual-bienestar/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 328",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/masaje-sunset-en-egipto/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 329",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/viaje-a-la-provenza/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 330",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/ritual-romance-de-sol-y-luna/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 331",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/viaje-al-eden-tropical/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 332",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/calma-spa/circuito-spa-90/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 333",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/calma-spa/circuito-spa-60/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 334",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/calma-spa/facial-express/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 335",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/calma-spa/ritual-piel-de-seda/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 336",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/calma-spa/masaje-relajante-calma/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 337",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/calma-spa/ritual-detox/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 338",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/calma-spa/circuito-spa-masaje-calma-para-2/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 339",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/calma-spa/circuito-spa-masaje-calma/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 340",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/calma-spa/circuito-spa-ritual-de-argan-para-2/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 341",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/calma-spa/circuito-spa-ritual-de-argan/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 342",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/calma-spa/masaje-en-pareja/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 343",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/calma-spa/masaje-piedras-calientes/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 344",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/calma-spa/masaje-de-espalda/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 345",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/calma-spa/masaje-indio-de-cabeza/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 346",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/calma-spa/masaje-express/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 347",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/wellness-beauty-bali-center/circuito-spa-adulto/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 348",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/wellness-beauty-bali-center/circuito-spa-nino/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 349",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/wellness-beauty-bali-center/circuito-spa-almuerzo-tipo-buffet-nino/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 350",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/wellness-beauty-bali-center/circuito-spa-almuerzo-tipo-buffet-adulto/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 351",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/wellness-beauty-bali-center/circuito-spa-cocktail-en-skybar-adulto/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 352",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/limpieza-hidratacion-con-acido-hialuronico/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 353",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/maderoterapia-radiofrecuencia/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 354",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/ritual-para-la-novia/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 355",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/noche-de-spa-vip/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 356",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/desconexion-para-dos-personas-residente/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 357",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/desconexion-para-dos-personas-no-residente/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 358",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/spa-de-noche-para-2-personas/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 359",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/spa-brunch-para-dos-no-residente/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 360",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/experiencia-aqua-para-1-persona-residente/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 361",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/experiencia-relax-para-2-personas-no-residente/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 362",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/experiencia-aqua-para-1-persona-no-residente/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 363",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/masaje-relajante-de-60-minutos/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 364",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/experiencia-mimate-residente/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 365",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/maderoterapia/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 366",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/cavitacion/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 367",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/envoltura-canaria/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 368",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/noche-de-spa-vip-para-2-personas/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 369",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/experiencia-relax-para-1-persona-residente/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 370",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/desconexion-para-dos-persona-residente/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 371",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/masaje-relajante-30-minutos/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 372",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/experiencia-respira-residente/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 373",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/spa-brunch-para-dos-residente/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 374",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/experiencia-romantica-residente/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 375",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/presoterapia/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 376",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/silicium-lift/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 377",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/body-sculpt/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 378",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/experiencia-mimate-no-residente/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 379",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/experiencia-relax-para-2-personas-residente/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 380",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/experiencia-aqua-para-2-personasresidente/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 381",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/experiencia-relax-para-1-persona-no-residente/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 382",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/experiencia-aqua-para-2-personas-no-residente/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 383",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/domingo-familiar-domingos/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 384",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/circuito-termal-nino-0-5-anos/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 385",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/circuito-termal/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 386",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/circuito-termal-nino-6-12-anos/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 387",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/viernes-juvenil/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 388",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/spa-de-noche/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 389",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/circuito-termal-vip/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 390",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/circuito-termal-nino-13-17-anos/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 391",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/cumpleanos-con-picoteo/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 392",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/aqua-baby/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 393",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/spa-brunch-residente/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 394",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/spa-brunch-no-residente/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 395",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/spa-hotel-monarque-fuengirola-park/pack-ritual-parejas/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 396",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/spa-hotel-monarque-fuengirola-park/desconexion-con-spa-y-belleza-para-2/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 397",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/spa-hotel-monarque-fuengirola-park/bienestar-con-spa-y-ritual-para-2/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 398",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/spa-hotel-monarque-fuengirola-park/piscina-climatizada/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 399",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/spa-hotel-monarque-fuengirola-park/ritual-aires-del-mediterraneo/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 400",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/spa-hotel-monarque-fuengirola-park/circuito-spa/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 401",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/the-ritz-carlton-spa/experiencia-wellness/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 402",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/peeling-clasico/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 403",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/programa-ayurveda-2-dias/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 404",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/bano-con-sales/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 405",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/bano-con-algas/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 406",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/ritual-de-la-polinesia/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 407",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/bano-con-esencias/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 408",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/bano-indonesio/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 409",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/envoltura-hidratante/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 410",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/lomi-lomi-80m/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 411",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/aromatico-80m/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 412",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/thai-50m/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 413",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/piedras-volcanicas-50m/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 414",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/ritual-oriental/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 415",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/ritual-canario/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 416",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/ritual-dragon-de-oro/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 417",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/hidroterapia/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 418",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/masaje-mahana/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 419",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/masaje-local-relajante/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 420",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/masaje-hindu/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 421",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/masaje-relajacion-50min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 422",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/escapada-relajante/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 423",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/spa-para-2-adultos/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 424",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/circuito-termal/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 425",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/masaje-sueco/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 426",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/ritual-evasion-de-los-sentidos/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 427",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/escapada-reconfortante/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 428",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/las-dunas-spa/circuito-spa-masaje-y-gastronomia/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 429",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/las-dunas-spa/masaje-de-aromaterapia-50-minutos-y-circuito-spa/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 430",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/las-dunas-spa/las-dunas-facial-deluxe-y-circuito-spa/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 431",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/las-dunas-spa/circuito-spa-y-desayuno/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 432",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/las-dunas-spa/circuito-spa/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 433",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/las-dunas-spa/circuito-spa-y-masaje/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 434",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/las-dunas-spa/masaje-con-piedras-calientes-80-minutos-y-circuito-spa/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 435",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/las-dunas-spa/vip-spa-suite/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 436",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/alum-spa-amaragua/circuito-spa/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 437",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/alum-spa-amaragua/circuito-spa-ritual/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 438",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/alum-spa-amaragua/aire-de-hadas/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 439",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/alum-spa-amaragua/mountain-flower/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 440",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/alum-spa-amaragua/masaje-tailandes/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 441",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/alum-spa-amaragua/circuito-spa-masaje-50/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 442",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/alum-spa-amaragua/masaje-personalizado-50/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 443",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/alum-spa-amaragua/circuito-spa-masaje-25/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 444",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/alum-spa-amaragua/masaje-personalizado-25/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 445",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/alum-spa-amaragua/passage-du-silence/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 446",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/alum-spa-amaragua/olive-flower-massage/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 447",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-ritual-de-marruecos-1h-45/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 448",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-pack-masaje-aromatico-50-y-piscina-spa-2h/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 449",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-masaje-aromatico-50/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 450",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-pack-ritual-de-bangalore-80-y-piscina-spa-2h/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 451",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-pack-ritual-de-marruecos-105-y-piscina-spa-2h/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 452",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-ritual-de-bangalore-1h-20/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 453",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-pack-ritual-java-45-y-piscina-spa-2h/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 454",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-ritual-de-java-45/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 455",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-pack-domingos-de-piscina-spa-y-brunch/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 456",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-pack-agua-y-relax/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 457",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-pack-relajese-y-disfrute-basic/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 458",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-pack-relajese-y-disfrute-premium/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 459",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-piscina-spa-2h-acceso/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 460",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/especial-parejas-2h-piscina-spa-masaje-aromatico-25/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 461",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-masaje-futura-mama-50/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 462",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-masaje-ayurvedico-50/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 463",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/viaje-a-la-ruta-de-las-delicias-circuito-en-pareja/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 464",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/circuito-spa-adulto/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 465",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/ritual-hydraluronic/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 466",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/viaje-magico-de-la-ruta-de-la-seda/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 467",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/viaje-al-eden-tropical/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 468",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/circuito-spa-1-adulto-2-ninos/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 469",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/circuito-spa-2-adultos-1-nino/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 470",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/circuito-spa-1-adulto-1-nino/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 471",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/masaje-deep-tisue/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 472",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/ritual-bienestar-masaje-relajante-50-en-pareja/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 473",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/ritual-romance-de-sol-y-luna-circuito-en-pareja/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 474",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/masaje-de-piedras-calientes/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 475",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/oasis-de-serenidad-circuito/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 476",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/experiencia-romantica-para-2/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 477",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/ritual-cleopatra-bano-aromatico-masaje-relajante-y-masaje-craneal/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 478",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/spa-2-adultos/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 479",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/spa-en-familia-2-adultos-y-1-nino/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 480",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/spa-en-familia-2-adultos-y-2-ninos/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 481",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/spa-boutique-estimar-valencia-4one/masaje-en-pareja-50-min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 482",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/spa-boutique-estimar-valencia-4one/spa-privado-y-gastronomia-para-2/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 483",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/spa-boutique-estimar-valencia-4one/spa-privado-y-desayuno/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 484",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/spa-boutique-estimar-valencia-4one/spa-privado-para-parejas-50-min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 485",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/spa-boutique-estimar-valencia-4one/pack-wellness-spa-y-masaje-para-dos-2-horas/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 486",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/spa-boutique-estimar-valencia-4one/friends-experience/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 487",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/naital-spa-wellness/circuito-spa-masaje-relajante/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 488",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/naital-spa-wellness/masaje-relajante-50-min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 489",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/naital-spa-wellness/circuito-spa-masaje-relajante-momento-en-pareja/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 490",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/naital-spa-wellness/masaje-facial-express/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 491",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/naital-spa-wellness/masaje-facial-con-rodillo-de-jade/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 492",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/naital-spa-wellness/masaje-descontracturante-50-min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 493",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/naital-spa-wellness/circuito-spa-1-adulto-1-nino/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 494",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/naital-spa-wellness/circuito-spa-2-adultos-1-nino/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 495",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/naital-spa-wellness/circuito-spa-2-adultos-2-ninos/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 496",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/naital-spa-wellness/circuito-spa/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 497",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-by-serawa-moraira/masaje-thai-oil/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 498",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-by-serawa-moraira/masaje-facial-de-oriente/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 499",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-by-serawa-moraira/drenaje-linfatico/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 500",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-by-serawa-moraira/spa-privado-para-dos-personas/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 501",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-by-serawa-moraira/masaje-deportivo/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 502",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-by-serawa-moraira/pack-spa-experience-para-dos-personas/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 503",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-by-serawa-moraira/masaje-relajante/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 504",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-by-serawa-moraira/masaje-prenatal/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 505",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-by-serawa-moraira/masaje-geo-serawa/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 506",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-by-serawa-moraira/masaje-hawaiano/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 507",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/hotel-neptuno-spa/circuito-spa-1-persona/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 508",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/hotel-neptuno-spa/circuito-spa-2-personas/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 509",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/hotel-neptuno-spa/spa-y-gastronomia-para-1/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 510",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/hotel-neptuno-spa/spa-masaje-y-gastronomia-para-1/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 511",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/hotel-neptuno-spa/spa-y-masaje-para-1/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 512",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/suite-time-for-two/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 513",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/suite-time-for-two-luxury/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 514",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/smooth-operator/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 515",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/wrap-remix/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 516",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/experiencia-spa-desayuno/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 517",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/momento-spa/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 518",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/inner-peace/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 519",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/momento-spa-masaje/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 520",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/synchronicity-50/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 521",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/deep-tissue-50/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 522",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/day-spa/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 523",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/experiencia-spa-cena-buffet/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 524",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/acceso-circuito-spa-cliente-residente/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 525",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/its-your-time/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 526",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/jelly-gum-massage/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 527",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/face-the-music/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 528",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/masaje-descarga-45min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 529",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/hidradermis-1/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 530",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/spa-relax-con-masaje-de-60-min-1/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 531",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/masaje-facial/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 532",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/masaje-45-min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 533",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/masaje-60-min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 534",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/espacio-acuatico-1/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 535",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/espacio-acuatico-y-templarium/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 536",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/ritual-ashanti-en-pareja/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 537",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/pack-especial-parejas-1/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 538",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/pack-ritual-parejas-2/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 539",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/spa-relax-en-pareja-masaje-de-30min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 540",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/chocolaterapia-1/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 541",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/spa-brunch/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 542",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/programa-antiestres-2/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 543",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/pack-romantico/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 544",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/spa-relax-con-masaje-de-30-min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 545",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/serenity/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 546",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/circuito-spa-nino-90/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 547",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/circuito-spa-buffet/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 548",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/hawaiian-lomi-lomi-parejas/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 549",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/masaje-infantil/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 550",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/circuito-spa-adulto-90/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 551",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/abhyanga/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 552",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/wellness-ritual-parejas/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 553",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/serenity-parejas/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 554",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/ritual-de-la-vela-parejas/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 555",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/prenatal-y-posnatal/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 556",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/circuito-spa-desayuno/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 557",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/hydraluronic/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 558",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/1-dia-en-spa-diamante-con-masaje-30/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 559",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/the-essential/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 560",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/centro-wellness-nirama-by-baobab-suites/ritual-de-aromaterapia-para-dos/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 561",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/centro-wellness-nirama-by-baobab-suites/terapia-facial-kobido/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 562",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/centro-wellness-nirama-by-baobab-suites/la-utima-experiencia-de-belleza-esse/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 563",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/centro-wellness-nirama-by-baobab-suites/terapia-champi/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 564",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/centro-wellness-nirama-by-baobab-suites/terapia-abhyanga/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 565",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/spa-del-palacete/sprunch-para-6-personas/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 566",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/spa-del-palacete/ritual-facial-y-corporal-geisha/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 567",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/spa-del-palacete/masaje-con-piedras-calientes/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 568",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/spa-del-palacete/masaje-ayurvedico-para-embarazadas/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 569",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/spa-del-palacete/circuito-spa-privado-masaje-relajante-o-descontracturante/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 570",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/spa-del-palacete/ritual-facial-detox/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 571",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/spa-del-palacete/ritual-facial-con-vitamina-c/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 572",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/spa-del-palacete/ritual-facial-y-corporal-de-chocolaterapia/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 573",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/spa-del-palacete/ritual-corporal-de-exfoliacion-e-hidratacion-tropical/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 574",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/spa-del-palacete/circuito-spa-privado-desayuno/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 575",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/spa-del-palacete/circuito-spa-privado/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 576",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/spa-del-palacete/ritual-corporal-al-andalus/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 577",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/sandara-wellness-spa/clase-yoga-pilates-qigong-2-pax/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 578",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/sandara-wellness-spa/clase-yoga-pilates-qigong-1-pax/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 579",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/sandara-wellness-spa/facial-hidratacion/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 580",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/sandara-wellness-spa/abama-total-wellness/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 581",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/sandara-wellness-spa/masaje-bio-energetico/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 582",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/sandara-wellness-spa/masaje-presion-profunda-60-min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 583",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/sandara-wellness-spa/facial-pureza-marina/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 584",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/sandara-wellness-spa/pura-experiencia-detox/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 585",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/sandara-wellness-spa/ritual-canario-de-piedras-calientes/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 586",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/sandara-wellness-spa/masaje-sandara-60-min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 587",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/sandara-wellness-spa/masaje-balines/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 588",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/sandara-wellness-spa/experiencia-ayurvedica/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 589",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/sandara-wellness-spa/especial-black-friday-masaje-balines/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 590",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/sandara-wellness-spa/circuito-de-aguas-cliente-alojado-2-personas/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 591",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/sandara-wellness-spa/circuito-de-aguas-cliente-externo-2-personas/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 592",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/spa-2-adultos/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 593",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/spa-en-familia-2-adultos-y-1-nino/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 594",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/spa-en-familia-2-adultos-y-2-ninos/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 595",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/spa-masaje-relajante-y-cava-para-2/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 596",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/spa-y-masaje-en-familia-2-adultos-y-1-nino/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 597",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/hydros-spa/circuito-de-aguas/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 598",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/hydros-spa/circuito-de-aguas-masaje/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 599",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/hydros-spa/circuito-de-luna-llena/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 600",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/tratamiento-facial-personalizado/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 601",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/laguna-tejido-profundo-50-min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 602",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/ritual-sensacion-oriental-1/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 603",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/circuito-spa-senda-de-los-sentidos/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 604",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/limpieza-profunda-tratamiento-contorno-de-ojos-is-clinical/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 605",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/despertar-laguna/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 606",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/laguna-relax-50/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 607",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/suite-royal-therapy/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 608",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/suenos-mediterraneos/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 609",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/laguna-tejido-profundo-25min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 610",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/laguna-localizado-20/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 611",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/circuito-spa-premium/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 612",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/circuito-spa-peeling/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 613",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/reflexologia-podal/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 614",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/ritual-pureza/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 615",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/masaje-sunset-in-egypt/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 616",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/masaje-relajante-80-min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 617",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/masaje-relajante-50-min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 618",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/circuito-spa-adulto/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 619",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/viaje-al-eden-tropical/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 620",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/ritual-romance-de-sol-y-luna/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 621",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/viaje-a-la-provenza/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 622",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/masaje-deep-tisue-80-min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 623",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/masaje-deep-tisue-50-min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 624",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/ritual-capricho-de-los-dioses/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 625",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/ritual-esplendor-radiante/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 626",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/viaje-magico-de-la-ruta-de-la-seda/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 627",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/ritual-brisa-de-ganesha/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 628",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/viaje-a-las-vinas-de-la-toscana/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 629",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/bain-de-luxe/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 630",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/deep-tissue-massage-localizado/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 631",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/luxury-skin-care-deluxe/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 632",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/craneo-facial-hindu/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 633",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/luxury-skin-care-premium/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 634",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/rituel-du-sensei/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 635",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/deep-tissue-completo/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 636",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/privilege-mi-momento-1/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 637",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/reina-de-egipto-massage/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 638",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/family-kids-luxury-1-adulto-1-nino-spa-y-masaje/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 639",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/circuito-spa-wellness-weekweekend/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 640",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/family-kids-spa-1/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 641",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/esencia-prenatal/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 642",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/viaje-a-la-prosperidad-y-el-exito/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 643",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/prosperidad-y-exito-massage/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 644",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/sueno-de-ambar-massage-by-morjana/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 645",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/circuito-spa-privado-luxury-en-pareja/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 646",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/jardin-de-los-deseos-man-massage-yang-by-alqvimia/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 647",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/jardin-de-los-deseos-woman-massage-yin-by-alqvimia/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 648",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/cofre-oasis-de-sabor-relax/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 649",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/cofre-jardin-de-los-deseos-en-pareja-ying-yang-by-alqvimia/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 650",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/viaje-sueno-de-oud-by-morjana/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 651",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/tranquility-body-face-by-comfortzone/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 652",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/cofre-privado-viaje-de-oriente-aroma-a-oud-ambar-by-morjana/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 653",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/jardin-de-los-deseos-woman-ritual-yin-by-alqvimia/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 654",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/viaje-sueno-de-ambar-by-morjana/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 655",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/tranquility-massage-by-comfortzone/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 656",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/essential-hot-oils-massage-1/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 657",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/sueno-de-oud-massage-by-morjana/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 658",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/masaje-a-4-manos/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 659",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/night-private-spa-spa-privado-nocturno/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 660",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/chocomassage-en-pareja/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 661",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/ayurveda-shiro-abhyanga/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 662",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/calm-free-massage/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 663",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/spa-privado-luxury-90min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 664",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/drenaje-linfatico/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 665",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/cofre-calm-wellness-en-pareja-con-cava-y-bombones/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 666",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/spa-momento-gourmet/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 667",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/naiad-wellness-center/circuito-termal-ritual-algas-intensive/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 668",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/naiad-wellness-center/circuito-termal-ritual-teide-magico/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 669",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/naiad-wellness-center/circuito-termal-ritual-secreto-de-la-india/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 670",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/naiad-wellness-center/circuito-termal-masaje-con-piedras-calientes/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 671",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/naiad-wellness-center/circuito-termal-masaje-descontracturante/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 672",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/naiad-wellness-center/circuito-termal-cava-y-chocolate/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 673",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/naiad-wellness-center/circuito-termal-ritual-afrodita/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 674",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/naiad-wellness-center/circuito-termal-masaje-personalizado/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 675",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/naiad-wellness-center/circuito-termal-masaje-ayurveda/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 676",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/naiad-wellness-center/circuito-termal-masaje-relajante/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 677",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/naiad-wellness-center/circuito-termal-tratamiento-facial-basico/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 678",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/naiad-wellness-center/circuito-termal-reflexologia-podal/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 679",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/naiad-wellness-center/circuito-termal/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 680",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/naiad-wellness-center/ritual-alma-de-naiad-para-el/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 681",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/naiad-wellness-center/ritual-alma-de-naiad-para-ella/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 682",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/oasis-de-la-serenidad-by-alqvimia/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 683",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/tratamiento-essentially-beautiful-by-alqvimia/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 684",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/spa-relax-de-m-v/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 685",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/gastro-wellness-experience-cena-spa-mascarilla/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 686",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/wine-wellness-para-dos-especial-navidad/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 687",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/wellness-christmas-day-pass-especial-navidad/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 688",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/masaje-en-pareja/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 689",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/wellness-brunch-experience-desayuno-buffet-spa-masaje/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 690",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/spa-deluxe/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 691",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/day-pass-masaje-cocktail/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 692",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/spa-deluxe-para-2/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 693",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/masaje-relajante/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 694",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/momento-spa-masaje-50/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 695",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/momento-spa-masaje-30/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 696",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/spa-privado-de-l-v/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 697",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/spa-relax-de-s-d/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 698",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/spa-break-de-s-d/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 699",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/spa-break-de-m-v/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 700",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/spa-privado-de-s-d/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 701",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/wellness-best-sellers-christmas-edition-especial-navidad/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 702",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/spa-sensations-marbella-coral-beach/energizer-40-min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 703",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/spa-sensations-marbella-coral-beach/detox-40-min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 704",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/spa-sensations-marbella-coral-beach/swedish-massage/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 705",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/spa-sensations-marbella-coral-beach/deep-tissue/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 706",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/spa-sensations-marbella-coral-beach/stone-therapy/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 707",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/spa-sensations-marbella-coral-beach/foot-reflexology/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 708",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/spa-sensations-marbella-coral-beach/booster-vitamin-c/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 709",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/spa-sensations-marbella-coral-beach/essences-senses/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 710",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/spa-sensations-marbella-coral-beach/energizer-55-min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 711",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/spa-sensations-marbella-coral-beach/detox-55-min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 712",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/spa-sensations-marbella-coral-beach/circuito-hydrowellness/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 713",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/love-secret/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 714",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/mas-happy-mum/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 715",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/mas-deep-tissue/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 716",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/piedras-calientes-pareja/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 717",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/entrada-spa/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 718",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/entrada-spa-nino-5-15-anos/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 719",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/piedras-calientes/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 720",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/mas-antiestres/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 721",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/mas-antiestres-pareja/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 722",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/body-care-uli-massage-parejas/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 723",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/mas-baby-relax/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 724",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/body-care-uli-massage/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 725",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/mood-spa/indibar-legs-relief/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 726",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/mood-spa/relax-renew-massage-60-min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 727",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/mood-spa/hot-stones/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 728",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/mood-spa/ayurvedic-shirodhara-abhyanga/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 729",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/mood-spa/peeling-massage-cocooning/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 730",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/mood-spa/indibar-corporal/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 731",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/mood-spa/signature-mediterranean-lifting-anti-aging/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 732",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/mood-spa/ayurvedic-pinda-herbal-therapy-60-min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 733",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/mood-spa/precious-pearl/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 734",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/mood-spa/anti-stress-back-remedy/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 735",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/mood-spa/mediterranean-bliss-face-body/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 736",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/mood-spa/indibar-facial/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 737",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/mood-spa/candle-night/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 738",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/mood-spa/circuito-spa-2-horas/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 739",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/malaga/mood-spa/deep-tissue-60-min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 740",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/experiencia-cabina-vip-pareja/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 741",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/facial-express/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 742",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/ritual-maderoterapia/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 743",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/experiencia-air-bio-spa-pareja/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 744",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/air-bio-spa-privado-60-min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 745",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/circuito-spa-privado-60-min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 746",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/experiencia-bio-spa-110/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 747",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/bio-masaje-relajante-50-min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 748",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/experiencia-bio-spa-85-pareja/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 749",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/experiencia-bio-spa-110-pareja/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 750",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/experiencia-bio-spa-85/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 751",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/circuito-spa-con-oasis-bio-120-min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 752",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/circuito-spa-familiar-60-min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 753",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/bio-masaje-para-ninos/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 754",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/bio-masaje-de-piedras-calientes/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 755",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/experiencia-spa-privado/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 756",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/experiencia-familiar-privada-padres-e-hijos/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 757",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/experiencia-cabina-vip/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 758",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/ritual-bio-spa/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 759",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/ritual-bio-spa-luxury/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 760",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/ritual-belleza-oriental-duo/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 761",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/ritual-thai-origen-duo/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 762",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/ritual-thai-experience-duo/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 763",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/ritual-thai-experience/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 764",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/ritual-thai-origen/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 765",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/masaje-thai-embarazadas-nuad-kon-thong-spa/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 766",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/masaje-de-bambu-spa/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 767",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/envoltura-natural-para-una-piel-perfecta-spa/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 768",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/masaje-thai-con-aceite-spa/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 769",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/masaje-thai-4-manos-spa/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 770",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/masaje-thai-pareja-spa/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 771",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/circuito-spa-wellness/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 772",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/masaje-thai-tradicional-pareja-spa/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 773",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/spa-privado-2/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 774",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/manicura-senza/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 775",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/spa-privado-pareja/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 776",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/experiencia-bio-spa-con-masaje-25-min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 777",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/experiencia-bio-spa-con-masaje-50-min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 778",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/bio-reflexologia/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 779",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/facial-expres/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 780",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/facial-calmante/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 781",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/bio-masaje-relajante-50-min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 782",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/bio-masaje-muscular-profundo-50-min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 783",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/bio-masaje-relajante-25-min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 784",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/bio-masaje-para-ninos/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 785",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/bio-masaje-prenatal/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 786",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/bio-masaje-de-piedras-calientes/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 787",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/bio-masaje-muscular-profundo-25-min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 788",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/abhyanga/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 789",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/prenatal-y-posnatal/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 790",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/esencias-y-sentidos/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 791",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/circuito-privado-spa/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 792",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/el-escape-perfecto/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 793",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/custom/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 794",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/programa-zen-parejas/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 795",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/hydraluronic/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 796",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/signature-cookbook/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 797",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/the-essential/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 798",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/wellness-ritual/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 799",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-mirador/manana-deluxe-desayuno-con-circuito-termal-120min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 800",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-mirador/circuito-hydrowellness/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 801",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-mirador/gastro-spa-almuerzo-circuito-de-aguas-masaje-55-min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 802",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-mirador/gastro-spa-almuerzo-circuito-de-aguas/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 803",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-mirador/manana-deluxe-desayuno-circuito-de-aguas-masaje-55-min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 804",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-mirador/manana-deluxe-desayuno-circuito-de-aguas-masaje-30-min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 805",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-mirador/gastro-spa-almuerzo-circuito-de-aguas-masaje-30-min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 806",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-mirador/after-work/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 807",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/body-care-labranda-spa-wellness/est-facial-hidratante/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 808",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/body-care-labranda-spa-wellness/mas-happy-mum/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 809",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/body-care-labranda-spa-wellness/mas-deep-tissue/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 810",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/body-care-labranda-spa-wellness/body-care-uli-massage/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 811",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/body-care-labranda-spa-wellness/mas-baby-relax/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 812",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/body-care-labranda-spa-wellness/love-secret/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 813",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/body-care-labranda-spa-wellness/piedras-calientes/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 814",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/body-care-labranda-spa-wellness/mas-antiestres/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 815",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/antiestres/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 816",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/sabila-signature-massage/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 817",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/cocoon-therapy-envolturas/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 818",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/stone-therapy-1/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 819",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/ocean-prodigy/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 820",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/serenity/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 821",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/deep-tissue-1/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 822",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/essences-senses/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 823",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/booster-vitamin-c/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 824",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/ocean-prodigy-force/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 825",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/ocean-intense/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 826",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/excellence-marine-collagen-lift/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 827",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/ayurveda-royal-thai-poultice/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 828",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/deep-tissue/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 829",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/lomi-lomi-nui-1/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 830",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/peeling/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 831",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/resugence/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 832",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/day-pass-spa-masaje-25min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 833",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/spa-masaje-almuerzo/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 834",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/sabila-signature-ritual/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 835",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/circuito-hydrowellness-1/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 836",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/spa-masaje-almuerzo-1/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 837",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/bio-spa-fanabe/experiencia-bio-spa-con-masaje-50-min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 838",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/bio-spa-fanabe/experiencia-bio-spa-con-masaje-25-min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 839",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/bio-spa-fanabe/bio-reflexologia/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 840",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/bio-spa-fanabe/facial-express-1/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 841",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/bio-spa-fanabe/facial-calmante/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 842",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/bio-spa-fanabe/bio-masaje-muscular-profundo-50-min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 843",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/bio-spa-fanabe/bio-masaje-relajante-50-min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 844",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/bio-spa-fanabe/bio-masaje-relajante-25-min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 845",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/bio-spa-fanabe/bio-masaje-de-piedras-calientes/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 846",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/bio-spa-fanabe/bio-masaje-prenatal/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 847",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/bio-spa-fanabe/bio-masaje-para-ninos/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 848",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/bio-spa-fanabe/bio-masaje-muscular-profundo-25-min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 849",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-sandos-monaco/circuito-spa-y-masaje-aromaterapia/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 850",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-sandos-monaco/circuito-spa/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 851",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-sandos-monaco/circuito-spa-buffet-libre-con-bebidas-incluidas-1/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 852",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-sandos-monaco/circuito-spa-masaje-y-banera-privada-para-2/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 853",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/alicante/spa-sandos-monaco/circuito-spa-para-2/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 854",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/azz-valencia-congress-spa/circuito-termal-masaje-20-pack-romantico-para-2-personas/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 855",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/azz-valencia-congress-spa/circuito-spa-90-masaje-20-para-2-personas/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 856",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/azz-valencia-congress-spa/circuito-spa-90-masaje-40-para-2-personas/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 857",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/azz-valencia-congress-spa/circuito-termal/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 858",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/valencia/azz-valencia-congress-spa/circuito-termal-para-2-personas/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 859",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/circuito-relax-1-entrada/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 860",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/neurosedante-con-aromaterapia-relajante/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 861",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/oxigenacion-facial/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 862",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/bamboo-scrub/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 863",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/peeling-sales-envoltura-de-chocolate/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 864",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/masaje-lomi-lomi/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 865",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/masaje-de-espalda-y-cuello-25-min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 866",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/spalopia-com-circuito-spa-masaje-y-buffet-para-2/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 867",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/spalopia-com-circuito-spa-y-buffet-para-2/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 868",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/spalopia-com-circuito-relax-para-2/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 869",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/diamond-rose-experiencia/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 870",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/oro-y-aceites-esenciales-relajantes-25-min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 871",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/piedras-calientes/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 872",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/chocolate-relajante-25-min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 873",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/ayurveda/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 874",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/facial-ritual-of-beauty-30-min/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  },
  {
    name: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.name ?? "Service landing: 875",
    path: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/banera-hidromasaje/",
    props: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.props ?? false,
    meta: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta || {},
    alias: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.alias || [],
    redirect: ServiceDetail_46pagesh_puxeZTctSSqM0bqOPfLJB9yE4XI6uyM8NWgcnrnQMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-spas-8c08844a-61f1-4105-b166-7aaec32796e0/b2c-build/core/pages/service/ServiceDetail.page.vue")
  }
]